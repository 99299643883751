import { defineStore } from 'pinia';
import { User } from '@supabase/supabase-js';
import { supabase } from '@/lib/supabaseClient';
import router from '@/router';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL as string;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY as string;

if (!supabaseUrl || !supabaseAnonKey) {
    throw new Error('Missing Supabase environment variables');
}

interface AuthState {
    user: (User & { full_name?: string }) | null;
    loading: boolean;
    error: string | null;
}

export const useAuthStore = defineStore('auth', {
    state: (): AuthState => ({
        user: null,
        loading: false,
        error: null,
    }),
    actions: {
        async login(email: string, password: string) {
            this.loading = true;
            this.error = null;
            try {
                const { data, error } = await supabase.auth.signInWithPassword({
                    email,
                    password,
                });
                if (error) throw error;
                this.user = data.user;
                router.push('/');
            } catch (error) {
                if (error instanceof Error) {
                    this.error = error.message;
                } else {
                    this.error = 'An unknown error occurred';
                }
            } finally {
                this.loading = false;
            }
        },
        async logout() {
            const { error } = await supabase.auth.signOut();
            if (error) this.error = error.message;
            else {
                this.user = null;
                router.push('/login');
            }
        },
        async checkUser() {
            const { data } = await supabase.auth.getUser();
            this.user = data.user;
        },
    },
});
