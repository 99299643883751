import { defineStore } from 'pinia';

export const useDomainStore = defineStore('domain', {
    state: () => ({
        domains: [
            'Alle domeinen',
            'Afvalstoffen',
            'Geluid',
            'Bodem',
            'Energie',
            'Lucht',
            'Veiligheid',
        ],
    }),
    actions: {
        fetchDomains() {
            // In the future, this could be an API call
        },
    },
});
