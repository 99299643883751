import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import HomeView from '../views/HomeView.vue';
import AboutView from '../views/AboutView.vue';
import LoginView from '../views/LoginView.vue';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeView,
            meta: { requiresAuth: true },
        },
        {
            path: '/about',
            name: 'about',
            component: AboutView,
            meta: { requiresAuth: true },
        },
        {
            path: '/login',
            name: 'login',
            component: LoginView,
        },
    ],
});

router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore();
    await authStore.checkUser();

    if (to.meta.requiresAuth && !authStore.user) {
        next('/login');
    } else if (authStore.user && to.path === '/login') {
        next('/');
    } else {
        next();
    }
});

export default router;
