import { defineStore } from 'pinia';
import { supabase } from '@/lib/supabaseClient';
import { Checklist, ChecklistSection } from '@/types/checklist';
import { useAuthStore } from './auth';

interface ChecklistProgress {
    id?: number;
    checklist_id: number;
    item_id: string;
    checked: boolean;
    checked_at: string | null;
}

export const useChecklistStore = defineStore('checklist', {
    state: () => ({
        checklists: [] as Checklist[],
    }),
    actions: {
        async fetchChecklists() {
            const { user } = useAuthStore();
            const { data, error } = await supabase
                .from('checklists')
                .select('*')
                .eq('user_id', user?.id)
                .order('created_at', { ascending: false });

            if (error) {
                console.error('Error fetching checklists:', error);
                return;
            }

            this.checklists = data as Checklist[];
        },
        async createChecklist(checklist: {
            name: string;
            checklist: string[];
            user_id: number;
        }) {
            const { data, error } = await supabase
                .from('checklists')
                .insert({
                    title: checklist.name,
                    checklist: checklist.checklist,
                    user_id: checklist.user_id,
                })
                .select();

            if (error) {
                console.error('Error creating checklist:', error);
                return;
            }

            if (data) {
                this.checklists.unshift(data[0] as Checklist);
            }
        },
        async fetchChecklistProgress(checklistId: number) {
            const { data, error } = await supabase
                .from('checklist_progress')
                .select('*')
                .eq('checklist_id', checklistId);

            if (error) {
                console.error('Error fetching checklist progress:', error);
                return [];
            }

            return data;
        },
        async updateChecklistProgress(progress: ChecklistProgress) {
            const { data, error } = await supabase
                .from('checklist_progress')
                .upsert({
                    checklist_id: progress.checklist_id,
                    item_id: progress.item_id,
                    checked: progress.checked,
                    checked_at: progress.checked_at,
                })
                .select()
                .single();

            if (error) {
                console.error('Error updating checklist progress:', error);
                throw error;
            }

            return data;
        },
    },
});
