<script setup lang="ts">
import { ref, computed } from 'vue';
import { useRoleStore } from '@/stores/role';
import { useDomainStore } from '@/stores/domain';
import { useAuthStore } from '@/stores/auth';
import { supabase } from '@/lib/supabaseClient';
import Select from 'primevue/select';

const roleStore = useRoleStore();
const domainStore = useDomainStore();
const authStore = useAuthStore();

const selectedRole = ref(null);
const selectedDomain = ref(null);
const selectedFile = ref<File | null>(null);
const isGenerating = ref(false);
import type { ChecklistSection } from '@/types/checklist';

const generatedChecklist = ref<ChecklistSection[]>([]);
const currentChecklistId = ref<number | null>(null);

const emit = defineEmits<{
    (e: 'close'): void;
}>();

const handleFileUpload = (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target.files) {
        selectedFile.value = target.files[0];
    }
};

const generateChecklist = async () => {
    if (!selectedRole.value || !selectedDomain.value || !selectedFile.value) {
        alert('Please fill in all fields and upload a PDF file.');
        return;
    }

    isGenerating.value = true;

    try {
        // Store the checklist in Supabase first
        const { data: insertedChecklist, error: insertError } = await supabase
            .from('checklists')
            .insert({
                name: `${selectedRole.value} - ${selectedDomain.value} - ${selectedFile.value.name}`,
                user_id: authStore.user?.id,
                checklist: [],
            })
            .select()
            .single();

        if (insertError) throw insertError;

        // Store the checklist ID
        currentChecklistId.value = insertedChecklist.id;

        // Prepare file for upload
        const formData = new FormData();
        formData.append('data', selectedFile.value);
        formData.append('checklistId', insertedChecklist.id);
        formData.append('role', selectedRole.value);
        formData.append('domain', selectedDomain.value);

        // Send request to N8N
        const response = await fetch(
            'https://n8n.7apps.nl/webhook/b33f28db-fd48-4aea-911b-866b30036da6',
            {
                method: 'POST',
                body: formData,
            },
        );

        generatedChecklist.value = (await response.json())[0].output;
        console.log(generatedChecklist.value);
    } catch (error) {
        console.error('Error generating checklist:', error);
        alert(
            'An error occurred while generating the checklist. Please try again.',
        );
    } finally {
        isGenerating.value = false;
    }
};

const storeChecklist = async () => {
    if (!currentChecklistId.value) {
        console.error('No checklist ID found');
        return;
    }

    try {
        const { error } = await supabase
            .from('checklists')
            .update({
                checklist: generatedChecklist.value,
            })
            .eq('id', currentChecklistId.value);

        if (error) throw error;
        emit('close');
    } catch (error) {
        console.error('Error updating checklist:', error);
        alert(
            'An error occurred while saving the checklist. Please try again.',
        );
    }
};

const isFormValid = computed(() => {
    return selectedRole.value && selectedDomain.value && selectedFile.value;
});

const roleOptions = computed(() =>
    roleStore.roles.map(role => ({ name: role, value: role })),
);

const domainOptions = computed(() =>
    domainStore.domains.map(domain => ({ name: domain, value: domain })),
);
</script>

<template>
    <form @submit.prevent="generateChecklist" class="space-y-4">
        <div>
            <label for="role" class="block text-sm font-medium text-gray-700"
                >Role</label
            >
            <Select
                v-model="selectedRole"
                :options="roleOptions"
                optionLabel="name"
                optionValue="value"
                placeholder="Select a role"
                class="w-full"
                :class="{ 'p-invalid': !selectedRole }"
            />
        </div>
        <div>
            <label for="domain" class="block text-sm font-medium text-gray-700"
                >Domain</label
            >
            <Select
                v-model="selectedDomain"
                :options="domainOptions"
                optionLabel="name"
                optionValue="value"
                placeholder="Select a domain"
                class="w-full"
                :class="{ 'p-invalid': !selectedDomain }"
            />
        </div>
        <div>
            <label for="file" class="block text-sm font-medium text-gray-700"
                >Upload PDF</label
            >
            <input
                type="file"
                id="file"
                @change="handleFileUpload"
                accept=".pdf"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
        </div>
        <div v-if="!generatedChecklist.length">
            <button
                type="submit"
                :disabled="!isFormValid || isGenerating"
                class="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                :class="{
                    'opacity-50 cursor-not-allowed':
                        !isFormValid || isGenerating,
                }"
            >
                <template v-if="isGenerating">
                    <svg
                        class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                        ></circle>
                        <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                    Generating...
                </template>
                <template v-else> Generate Checklist </template>
            </button>
        </div>
        <div v-else>
            <h4 class="text-lg font-medium mb-2">Generated Checklist</h4>
            <div
                v-for="(section, sectionIndex) in generatedChecklist"
                :key="section.heading"
                class="mb-6"
            >
                <h5 class="text-md font-medium mb-2">{{ section.heading }}</h5>
                <div
                    v-for="(item, itemIndex) in section.items"
                    :key="itemIndex"
                    class="mb-2"
                >
                    <textarea
                        v-model="
                            generatedChecklist[sectionIndex].items[itemIndex]
                        "
                        rows="2"
                        class="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    ></textarea>
                </div>
            </div>
            <button
                @click="storeChecklist"
                type="button"
                class="mt-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
                Store Checklist
            </button>
        </div>
    </form>
</template>

<style>
:deep(.p-select) {
    width: 100%;
}

:deep(.p-select-items) {
    max-height: 200px;
}
</style>
