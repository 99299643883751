import { defineStore } from 'pinia';

export const useRoleStore = defineStore('role', {
    state: () => ({
        roles: ['Toezichthouder', 'Vergunningverlener', 'Handhaver', 'BOA'],
    }),
    actions: {
        fetchRoles() {
            // In the future, this could be an API call
        },
    },
});
